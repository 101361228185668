import React from 'react'
import styled from 'styled-components'
import { GetStaticProps, NextPage } from 'next'
import { GlobalProps, withLayoutProps } from '../globals/layout'
import { Layout } from '../components/layout/layout'
import { Error } from '../templates/error'

const StyledWrapper = styled.div`
  header {
    display: none;
  }

  footer {
    display: none;
  }
`

export type InternalServerErrorPageProps = GlobalProps & {

}

const InternalServerErrorPage: NextPage<InternalServerErrorPageProps> = ({ ...layoutProps }) => {
  return (
    <StyledWrapper>
      <Layout { ...layoutProps }>
        <Error statusCode={'500'} />
      </Layout>
    </StyledWrapper>
  )
}

export default InternalServerErrorPage

export const getStaticProps: GetStaticProps = async () => {
  return withLayoutProps({
    path: '/',
  })
}
