import React from 'react'
import { HomeBanner } from '../../components/banner/HomeBanner'
import { HomeBanners } from '../../components/banner/HomeBanners'
import { Container } from '@material-ui/core'
import styled from 'styled-components'
import Link from 'next/link'
import { CreditUnionLogo } from '../../components/logo/logo'

const StyledHomeBanner = styled(HomeBanner)`
  > div[class*="HomeBanner__BannerImageContainer"] {
    z-index: 2000;
  }

  > div[class*="HomeBanner__StyledContent"] {
    > h2[class*="HomeBanner__StyledTitle"] {
      &:first-child {
        font-size: ${props => props.theme.typography.pxToRem(17)};
      }

      &:last-child {
        font-size: ${props => props.theme.typography.pxToRem(60)};
      }
    }

    > p[class*="HomeBanner__StyledSubtitle"] {
      font-size: ${props => props.theme.typography.pxToRem(19)};
      display: block;
    }
  }
`

const StyledHomeContainer = styled(Container)`
  padding: 0;
`

const StyledErrorContentWrapper = styled(Container)`
  margin-top: ${props => props.theme.typography.pxToRem(22)};

  > figure {
    margin-top: ${props => props.theme.typography.pxToRem(22)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(119)};
    }
  }

  div[class^=HomeBanner__ImageBox] {
    margin-top: ${props => props.theme.typography.pxToRem(22)};
  }
`

export type ErrorProps = {
  statusCode?: '404' | '500'
}

export const Error: React.FC<ErrorProps> = ({ statusCode, ...props }) => {
  return (
    <StyledHomeContainer maxWidth={false}>
      <StyledErrorContentWrapper>
        <CreditUnionLogo />
        <HomeBanners {...props}>
          {Array.from({ length: 1 }).map((_, i) => (
            <StyledHomeBanner image src={`/images/${statusCode}.png`} key={i}>
              
              <HomeBanner title text={`ERROR ${statusCode}`} />
              <HomeBanner title text={'Oops !'} />
              <HomeBanner subtitle text={
                statusCode === '404'
                  ? 'Sorry we can’t seem to find the page you’re looking for, maybe it’s a bad link or maybe it was mistyped.'
                  : 'Something went wrong, the server is not responding. Please try again later.'
              }/>
              <HomeBanner subtitle text={(
                <>
                  Visit our <Link href="/">homepage</Link> to discover more products and tools or <Link href="/help-and-support/contact-us/">contact us</Link>
                </>
              )} />

            </StyledHomeBanner>
          ))}
        </HomeBanners>
      </StyledErrorContentWrapper>
    </StyledHomeContainer>
  )
}
